<template>
  <q-card class="row" style="margin-bottom: 100px;">
    <q-tabs
      style="padding: 24px 0; box-shadow: 10px 0px 20px 0px rgba(0,0,0,0.04);"
      class="col-2 text-primary"
      v-model="tab"
      vertical
    >
      <q-tab :name="0" label="IPC国际分类" />
      <q-tab :name="1" label="LOC洛迦诺分类" />
      <q-tab :name="2" label="cpc(合作专利分类)" />
      <!-- <q-tab :name="3" label="国民经济行业分类" /> -->
    </q-tabs>
      <q-tab-panels
        class="col-10"
        v-model="tab"
        animated
        vertical
        transition-prev="jump-up"
        transition-next="jump-up"
      >
        <q-tab-panel :name="0">
          <div class="text-h4 q-mb-md">IPC分类</div>
          <p>《国际专利分类表》（IPC分类）是根据1971年签订的《国际专利分类斯特拉斯堡协定》编制的，是国际通用的专利文献分类和检索工具，为世界各国所必备。由于新技术的不断涌现，专利文献每年增长约150万件，约有5000万件。按照第7版69000个组计算，平均每组包含的文献量超过700件。第8版IPC基本版约20 000条，包括部、大类、小类、大组和在某些技术领域的少量多点组的小组。第8 版IPC高级版约70 000条，包括基本版以及对基本版进一步细分的条目。高级版供属于PCT最低文献量的工业产权局和大的工业产权局使用，用来对大量专利文献进行分类。</p>
          <p>在系统中检索方式：ipc:(D OR E) 等同于 ipc:(D) OR ipc:(E)</p>
          <div style="height: 500px;">
            <q-tree
              no-connectors
              :nodes="ipc"
              node-key="id"
              @lazy-load="test"
            />
          </div>
        </q-tab-panel>

        <q-tab-panel :name="1">
          <div class="text-h4 q-mb-md">LOC分类</div>
          <p>Locarno中文是洛迦诺分类（LOC）一种工业品外观设计注册用国际分类，由《洛迦诺协定》（1968年）建立。洛迦诺分类第十版于2014年1月1日生效。国际上所采用的设计专利系统为根据Locarno分类表（Locarno Classification，洛迦诺分类表）所建立，其中该分类制度源于1968年所签署之Locarno Agreement（罗卡诺协定,亦译为洛迦诺协定）而来，而我国亦于90年10月24日所修正之专利法中采用该分类系统。</p>
          <p>在系统中检索方式：loc:(03 OR 04) 等同于 loc:(03) OR loc:(04)</p>
          <div style="height: 500px;">
            <q-tree
              no-connectors
              :nodes="loc"
              node-key="id"
              label-key="name"
            />
          </div>
        </q-tab-panel>

        <q-tab-panel :name="2">
          <div class="text-h4 q-mb-md">CPC分类</div>
          <p>2010年10月，美欧宣布合作开发联合专利分类体系（Cooperative Patent Classification，简称CPC）。CPC于2010年10月25日首次公布，其按照国际分类体系IPC的标准和结构进行开发，以欧洲专利分类号ECLA作为整个分类体系的基础，并结合美国专利分类USPC的成功实践经验，由欧洲专利局和美国专利商标局共同管理和维护。美国专利商标局承诺将在CPC实施之后放弃其近200年历史的USPC。</p>
          <p>在系统中检索方式：cpc:(F OR G) 等同于 cpc:(F) OR cpc:(G)</p>
          <div style="height: 500px;">
            <q-tree
              no-connectors
              :nodes="cpc"
              node-key="id"
              @lazy-load="cpcLazyLoad"
            />
          </div>
        </q-tab-panel>
          <!-- <q-tab-panel :name='3'>
            <div class="text-h4 q-mb-md">国民经济行业分类</div>
            <p>国民经济行业分类》国家标准于1984年首次发布，分别于1994年和2002年进行修订，2011年第三次修订，2017年第四次修订。该标准（GB/T4754-2017）由国家统计局起草，国家质量监督检验检疫总局、国家标准化管理委员会批准发布，于2017年10月1日实施。GB/T 4754-2017《国民经济行业分类》国家标准第1号修改单（以下简称第1号修改单）已经国家标准化管理委员会于2019年3月25日批准，自2019年3月29日起实施。</p>
            <p>系统暂无支持查询国民经济类</p>
          </q-tab-panel> -->
    </q-tab-panels>
  </q-card>
</template>

<script>
import loc from '/public/loc_data.js'

export default {
  data () {
    return {
      tab: 0,
      splitterModel: 0,
      ipc: [],
      loc: [],
      cpc: []
    }
  },
  created () {
    this.getIpcTree(0);
    this.getCpcTree(0);
    this.loc = loc
  },
  mounted () {
    console.log(loc)
  },
  methods: {
    test({ node, done }) {
      // 获取IPC二级列表
      const params = {
        parentId: node.id
      };
      this.$http
        .get(process.env.VUE_APP_API_BASE_URL + "/ipc/parent", { params })
        .then(response => {
          if (response.status === 200) {
            response.data.result.forEach(item => {
              item.label = item.mark + " " + item.name;
              item.lazy = true;
              item.noTick = true;
              // item.handler = node => this.handleClickOfIPC(node);
            });
          }
          setTimeout(() => {
            done(response.data.result);
          }, 1000);
        });
    },
    getIpcTree(args) {
      const query = args;
      if (query === 0) {
        // 获取IPC一级列表
        const params = {
          parentId: query
        };
        this.$http
          .get(process.env.VUE_APP_API_BASE_URL + "/ipc/parent", { params })
          .then(response => {
            if (response.status === 200) {
              if (response.data) {
                response.data.result.forEach(item => {
                item.label = item.mark + " " + item.name;
                item.noTick = true;
                item.lazy = true;
                // item.handler = node => this.handleClickOfIPC(node);
              });
              this.ipc = response.data.result;
              }
            }
          });
      }
    },
    getCpcTree(args) {
      const query = args;
      if (query === 0) {
        // 获取IPC一级列表
        const params = {
          parentid: query
        };
        this.$http
          .get(process.env.VUE_APP_API_BASE_URL + "/proxier/cpc", { params })
          .then(response => {
            if (response.status === 200) {
              if (response.data) {
                console.log(response)
                response.data.data.forEach(item => {
                item.label = item.mark + " " + item.name;
                item.noTick = true;
                item.lazy = true;
                item.handler = node => this.handleClickOfIPC(node);
              });
              this.cpc = response.data.data;
              }
            }
          });
      }
    },
    cpcLazyLoad ({ node, done }) {
      // 获取CPC二级列表
      const params = {
        parentid: node.id
      };
      this.$http
        .get(process.env.VUE_APP_API_BASE_URL + "/proxier/cpc", { params })
        .then(response => {
          if (response.status === 200) {
            response.data.data.forEach(item => {
              item.label = item.mark + " " + item.name;
              item.lazy = true;
              item.noTick = true;
            });
          }
          setTimeout(() => {
            done(response.data.data);
          }, 1000);
        });
    }
  }
}
</script>

<style scoped>
.q-tab-panel {
  padding: 24px;
}
.q-tab-panel > .text-h4 {
  font-size: 16px;
  font-weight: 800;
  color: #333333;
}
.q-tree > .q-tree__node {
  background: rgba(0,0,0,0.02) !important;
}
</style>
